.pcr-app[data-theme="nano"] .pcr-selection {
    grid-gap: 0;
    grid-template-columns: 0fr 5fr;
}

.pcr-app[data-theme="nano"] .pcr-selection .pcr-color-chooser {
    margin-top: 0.6em;
}

.pcr-app[data-theme="nano"] .pcr-selection .pcr-color-opacity {
    height: 0;
}

.pickr .pcr-button:focus,
.pickr .pcr-button:active {
    box-shadow: none;
}

.pickr {
    transform: translateY(-1.5px);
}
